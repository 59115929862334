import React from "react";
import { Home } from "./components/Home";

const App: React.FC = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default App;
